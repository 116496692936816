// symbol scss
$pink-color: #a13858;
$blue-color: #39e0e8;

%grid-center {
	width: 1200px;
	margin: 0 auto;
}
@mixin mix-header($c1: #333, $c2: #888888) {
	text-align: center;
	.title {
		color: $c1;
		font-size: 36px;
	}
	.explain {
		margin-top: 10px;
		color: $c2;
		font-size: 22px;
	}
}

@mixin flex($direction: row, $justify: space-between, $align: center) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
}

%grid-text {
	@include flex(column, space-around, center);
	font-size: 14px;
	color: #999999;
	line-height: 20px;
}
// 通用样式
html,
body {
	.icon {
		width: 40px;
		height: 40px;
		// vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
		display: block;
	}
}
.min-header {
	display: none;
}
.container {
	.digital-resources,
	.min-system-display,
	.digital-services,
	.digital-communication,
	.min-classic-case,
	.min-client-presentation {
		display: none;
	}
}
.min-contact-us {
	display: none;
}
//
header {
	height: 90px;
	.header-fix {
		.content {
			@extend %grid-center;
			@include flex();
			height: 90px;
			.company-icon {
				width: 180px;
				height: 56px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.nav-list {
				@include flex(row, space-between, center);
				height: 100%;
				li {
					margin: 0 10px;
					height: 100%;
					box-sizing: border-box;
					cursor: pointer;
					a {
						color: #666666;
						text-decoration: none;
						line-height: 60px;
						font-size: 16px;
						display: inline-block;
						width: 100%;
						height: 100%;
						padding: 10px;
					}
					&.active {
						border-bottom: solid 6px $pink-color;
						a {
							color: $pink-color;
							// font-size: 18px;
						}
					}
				}
			}
		}
		&.header-active {
			position: fixed;
			width: 100%;
			top: 0;
			background-color: #fff;
			box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
			z-index: 2;
		}
	}
}

.container {
	.banner {
		width: 100%;
		.banner-image {
			width: 100%;
			position: relative;
			clear: both;
			img {
				width: 100%;
			}
			.banner-content {
				@extend %grid-center;
				position: absolute;
				width: 600px;
				height: 100%;
				top: 0;
				left: 50%;
				margin-left: -600px;
				color: #fff;
				@include flex(column, center, flex-start);
				.title-text {
					font-size: 18px;
				}
				.title {
					font-size: 48px;
					margin-top: 20px;
				}
				.text {
					margin-top: 36px;
					line-height: 24px;
				}
				.button {
					margin-top: 92px;
				}
				.use-sys {
					margin-top: 40px;
				}
			}
			.banner-content2 {
				width: 400px;
				.title {
					font-size: 30px;
					margin-top: 20px;
				}
			}
		}
	}
	.product-service {
		padding-top: 100px;
		@extend %grid-center;
		.header {
			@include mix-header();
		}
		.grid-box {
			margin-top: 80px;
			@include flex(row, space-around, center);
			.grid-content {
				text-align: center;
				.grid-text {
					margin-top: 46px;
					@extend %grid-text;
					position: relative;
					&::before {
						content: '';
						width: 30px;
						height: 1px;
						background: #bfbfbf;
						position: absolute;
						top: -25px;
						left: 50%;
						margin-left: -15px;
					}
				}
			}
		}
	}
	.company-introdution-boder {
		margin-top: 80px;
		background-image: url('../assets/image/banner/公司介绍.png');
		background-size: 100% 100%;
		width: 100%;
		height: 310px;
		.company-introdution {
			position: relative;
			clear: both;
			@extend %grid-center;
			height: 100%;
			color: #fff;
			padding-top: 70px;
			.header {
				@include mix-header(#fff, #fff);
			}
			.grid-text {
				@extend %grid-text;
				color: #fff;
				margin-top: 29px;
			}
		}
	}
	.solution {
		padding-top: 100px;
		padding-bottom: 80px;
		@extend %grid-center;
		.header {
			@include mix-header();
		}
		.grid-box {
			margin-top: 80px;
			@include flex(row, space-around, center);
			.grid-content {
				width: 320px;
				height: 270px;
				background: rgba(255, 255, 255, 1);
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
				padding: 0 30px;
				@include flex(column, flex-start, center);
				padding-top: 20px;
				.grid-text {
					margin-top: 20px;
					color: #666;
					line-height: 26px;
					text-align: justify;
					font-size: 14px;
					.red {
						color: $pink-color;
					}
				}
			}
		}
	}
	.manage-border,
	.protect-border,
	.server-border {
		background-image: url('../assets/image/banner/综合解决方案.png');
		width: 100%;
		height: 1080px;
		background-size: 100% 100%;
		position: relative;
		.manage {
			@extend %grid-center;
			position: relative;
			height: 100%;
			clear: both;
			.content {
				@include flex(row, space-between, flex-start);
				padding-top: 350px;
				.left {
					color: #999999;
					height: 450px;
					@include flex(column, flex-start, flex-start);
					.header {
						font-size: 28px;
						.title {
							color: #fff;
							.blue {
								color: $blue-color;
							}
						}
						.explation {
							font-size: 18px;
						}
					}
					.text {
						font-size: 14px;
						margin-top: 36px;
						color: #999999;
						line-height: 20px;
					}
					.icon-list {
						@include flex(row, flex-start, center);
						margin-top: auto;
						width: 100%;
						li {
							// width: 100px;
							width: 25%;
							height: 90px;
							cursor: pointer;
							@include flex(column, flex-end, flex-start);
							span {
								font-size: 14px;
								margin-top: 16px;
							}
							&.active {
								@keyframes fade {
									from {
									}
									to {
										color: $blue-color;
									}
								}
								animation: fade 0.1s linear alternate both;
								// color: $blue-color;
								.icon {
									@keyframes zoom {
										from {
											width: 40px;
											height: 40px;
										}
										to {
											width: 60px;
											height: 60px;
										}
									}
									animation: zoom 0.1s linear alternate both;
								}
							}
						}
						li + li {
							// margin-left: 36px;
						}
					}
					.view-detail {
						margin-top: 64px;
						cursor: pointer;
						// margin-top: auto;
					}
				}
				.right {
					margin-left: 120px;
					position: relative;
					&::after {
						content: '';
						position: absolute;
						width: 136px;
						height: 10px;
						background-color: $blue-color;
						border-radius: 2px;
						right: -10px;
						top: -10px;
					}
					&::before {
						content: '';
						position: absolute;
						width: 10px;
						height: 136px;
						background-color: $blue-color;
						border-radius: 2px;
						right: -10px;
						top: -10px;
					}
					.right2 {
						&::after {
							content: '';
							position: absolute;
							width: 136px;
							height: 10px;
							border-radius: 2px;
							background-color: $blue-color;
							left: -10px;
							bottom: -6px;
						}
						&::before {
							content: '';
							position: absolute;
							width: 10px;
							height: 136px;
							border-radius: 2px;
							background-color: $blue-color;
							left: -10px;
							bottom: -6px;
						}
					}
				}
			}
		}
		.tooltip {
			position: absolute;
			right: 40px;
			top: 50%;
			margin-top: -60px;
			color: #666666;
			.tip {
				font-size: 14px;
				margin-bottom: 40px;
				overflow: hidden;
				a {
					text-decoration: none;
					color: #666666;
					padding-right: 16px;
					float: left;
					width: 46px;
					text-align: justify;
					text-align-last: justify;
				}
				span {
					float: left;
					width: 10px;
					height: 10px;
					// border: 1px solid $blue-color;
					border-radius: 50%;
					// background-color: #666;
					background-image: radial-gradient(#666666 2px, transparent 0);
				}
				&.active {
					a {
						color: #ffffff;
					}
					span {
						display: inline-block;
						width: 10px;
						height: 10px;
						box-sizing: border-box;
						border: 1px solid $blue-color;
						border-radius: 50%;
						// padding: 2px;
						background-color: transparent;
						background-image: radial-gradient($blue-color 2px, transparent 0);
					}
				}
			}
			.decoration {
				// text-align: right;
				// margin-right: 5px;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					width: 1px;
					height: 34px;
					right: 5px;
					top: -38px;
					background: #a0a0a0;
				}
			}
		}
	}
	.system-display {
		padding-top: 100px;
		padding-bottom: 80px;
		@extend %grid-center;
		.header {
			@include mix-header();
		}
		.content {
			margin-top: 80px;
			@include flex(row, space-between, flex-start);
			height: 360px;
			.left {
				@include flex(column, flex-start, flex-start);
				height: 100%;
				.left-header {
					.title {
						color: #333333;
						font-size: 28px;
					}
					.explation {
						color: #888888;
						font-size: 16px;
					}
				}
				.text {
					margin-top: 30px;
					color: #666666;
					font-size: 14px;
					line-height: 24px;
				}
				.navlist {
					@include flex(row, space-between, center);
					margin-top: auto;
					margin-bottom: 40px;

					width: 100%;
					li {
						color: #777777;
						padding: 10px 30px;
						border-radius: 4px;
						cursor: pointer;
						&.active {
							color: #fff;
							background-color: $pink-color;
						}
					}
				}
			}
			.right {
				margin-left: 50px;
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
			}
		}
	}
	.classic-case-border {
		padding-top: 100px;
		padding-bottom: 80px;
		background: rgba(250, 251, 253, 1);
		.classic-case {
			@extend %grid-center;
			.header {
				@include mix-header();
			}
			.content {
				margin-top: 60px;
				.inside-content {
					padding: 20px 100px;
					.inside-content-border {
						box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
						@include flex(row, space-between, flex-start);
						height: 318px;
						.left {
							// margin-right: 20px;
						}
						.right {
							padding: 40px 60px 20px 40px;
							@include flex(column, flex-start, flex-start);
							height: calc(100% - 60px);
							background-color: #fff;
							.title {
								color: #333333;
								font-size: 24px;
								// margin-top: ;
							}
							.text {
								margin-top: 20px;
								color: #666666;
								font-size: 14px;
								line-height: 22px;
							}
							.date {
								color: #888888;
								font-size: 16px;
								margin-top: auto;
							}
						}
					}
				}
				
			}
		}
	}
	.client-presentation {
		padding-top: 100px;
		padding-bottom: 80px;
		.header {
			@include mix-header();
		}
		.content {
			@extend %grid-center;
			margin-top: 100px;
			position: relative;
			.showcase-container2 {
				margin: 0 80px;
				height: 170px;
				// overflow: hidden;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
			}
		}
	}
}
footer {
	width: 100%;
	height: 324px;
	background: #333;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: 40px;
		background-color: #a0a0a0;
	}
	.content {
		@extend %grid-center;
		color: #999;
		font-size: 14px;
		padding: 40px 0;
		height: calc(100% - 120px);
		@include flex(row, space-between, normal);
		.grid {
			@include flex(column, space-around, flex-start);
			justify-content: space-evenly;
			.title {
				color: #fff;
			}
			.text {
				span + span {
					margin-left: 40px;
				}
			}
		}
	}
}
